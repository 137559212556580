import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../layouts/de'
import Title from '../components/Titles/Title'
import ProjectCard from '../components/ProjectCard'
import PublicationCard from '../components/PublicationCard'
import SubSubTitle from '../components/Titles/SubSubTitle'

const lang = 'de'

const MemberTemplate = ({ data, location }) => {
  const {
    member,
    member: { projects, publications },
  } = data

  const translations = member.translations.find((t) => t.lang === lang)

  projects.sort(function (a, b) {
    return b.year - a.year
  })

  publications.sort(function (a, b) {
    return (
      new Date(b.publication_date).getFullYear() -
      new Date(a.publication_date).getFullYear()
    )
  })

  return (
    <Layout location={location}>
      <Helmet title={`${member.name}`} />
      <div className="relative">
        <Title>Profil</Title>
        <div className="flex flex-col bg-gray-200 sm:flex-row">
          {member.photo &&
            member.photo.localFile &&
            member.photo.localFile.childImageSharp && (
              <div className="flex sm:inline-block w-full sm:w-[250px] md:h-[250px] overflow-hidden">
                <GatsbyImage
                  image={getImage(member.photo.localFile.childImageSharp)}
                  className="w-1/3 sm:w-[250px] h-[200px] sm:h-[250px]"
                  imgStyle={{
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                  }}
                  alt={member.name}
                />
                <div className="flex flex-col w-2/3 p-5 sm:hidden">
                  <SubSubTitle>{member.name}</SubSubTitle>
                  <p className="text-sm text-gray-500 sm:text-md">
                    {member.job_title}
                  </p>
                  {member.email && (
                    <p className="flex flex-col justify-end flex-1">
                      <a
                        href={'mailto:' + member.email}
                        className="text-sm tracking-wide text-yellow-600 underline sm:text-md"
                      >
                        {member.email}
                      </a>
                    </p>
                  )}
                </div>
              </div>
            )}

          <div className="flex-col flex-1 hidden p-10 sm:flex">
            <article className="flex flex-col justify-between flex-1 pr-3 leading-relaxed font-text text-md">
              <div className="hidden sm:block">
                <SubSubTitle>{member.name}</SubSubTitle>
                <p className="text-gray-500">{member.job_title}</p>
              </div>
              {member.email && (
                <p className="hidden sm:block">
                  <a
                    href={'mailto:' + member.email}
                    className="tracking-wide text-yellow-600 underline"
                  >
                    {member.email}
                  </a>
                </p>
              )}
            </article>
          </div>
        </div>
        {translations && translations.bio ? (
          <article
            className="p-10 mb-12 leading-relaxed bg-gray-200 font-text text-md"
            dangerouslySetInnerHTML={{
              __html: translations.bio,
            }}
          />
        ) : (
          <div className="mb-12"></div>
        )}
        {projects && projects.length > 0 && (
          <div className="mb-12 sm:mx-20">
            <SubSubTitle className="ml-8 sm:ml-0">Projekte</SubSubTitle>
            <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {projects.map((p) => (
                <ProjectCard project={p} key={p.title} lang={lang} />
              ))}
            </section>
          </div>
        )}
        {publications && publications.length > 0 && (
          <div className="sm:mx-20">
            <SubSubTitle className="ml-8 sm:ml-0">Publikationen</SubSubTitle>
            {publications.map((pub) => (
              <PublicationCard content={pub} lang={lang} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default MemberTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    member: directusMember(id: { eq: $id }) {
      name
      job_title
      email
      active
      student_staff
      translations {
        lang
        bio
      }
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      projects {
        id
        year
        type
        slug
        title
        translations {
          language
          additional_info
          description
          title
        }
        partners {
          name
        }
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
      publications {
        id
        slug
        authors
        title
        publisher
        publication_date
        link
        type
        bibtex_entry
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
    }
  }
`
